import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isToken } from '../utils/is-token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq: HttpRequest<any> = req;

    if (isToken()) {
      authReq = req.clone({
        setHeaders: {
          token: localStorage.getItem('access-token')
        }
      });
    }
    return next.handle(authReq);
  }
}
